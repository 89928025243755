.TABLET {
  &.container {
    padding: 0 40px;
  }

  &.DEPTH4 {
    .content {
      .group-width-calculator-container,
      .group-container {
        flex-wrap: wrap;
      }
    }
  }
}
