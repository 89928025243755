@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  position: relative;
  display: flex;
  height: 100%;
  min-width: 0;

  &.shadow {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  &.overflow-hidden {
    overflow: hidden;
  }

  &.has-link {
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 1216px;
    width: 100%;
    margin: 0 auto;
  }
}
