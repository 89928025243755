@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.slider-container {
    width: 100%;
    overflow: hidden;

    .slider-rail {
      padding-left: 24px;
      display: flex;
      transition: transform 0.5s ease-out;
    }
}