@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ViewBlockTablet.module';
@import 'ViewBlockMobile.module';

.container {
  position: relative;
  width: 100%;

  .content {
    max-width: 1216px;
    margin: 0 auto;
    opacity: 0;
    transform: translate(0, 10px);

    .group-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 32px;
    }

    .group-width-calculator-container {
      display: flex;
      align-items: flex-start;
      gap: 32px;
      width: 100%;

      &.MOBILE {
        gap: 0px;
      }

      > div {
        content: '';
        width: 100%;
      }
    }

    &.isCapture {
      opacity: 1;
    }

    &.fadeIn {
      animation: fadeIn 0.4s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
