@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .slider-rail-container {
    width: 1216px;

    .slider-rail {
      display: flex;
      transition: transform 0.5s ease-out;
    }
  }
}