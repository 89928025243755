@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.slider-container {
    width: 100%;
    overflow: hidden;

  .slider-track {
    width: 100%;
    display: flex;
    white-space: nowrap;
    gap: 20px;

    &:hover {
      .slider-content {
        animation-play-state: paused;
      }
    }
    .slider-content {
      display: inline-flex;
      gap: 20px;
      animation: autoSlide linear infinite;
    }
    &.LEFT {
      .slider-content {
        animation-direction: reverse;

      }

    }
    &.RIGHT {
      .slider-content {
        animation-direction: inherit;
      }
    }
  }


}

@keyframes autoSlide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 20px));
  }
}